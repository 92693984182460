import { useContext }      from "react";
import { ConfigContext }   from "contexts/ConfigContext"
import { MenuItemContext } from "contexts/MenuContext";

export const useMenu = () => useContext(ConfigContext)

export const useMenuItems = (options) => {
  const handleMenu = useContext(MenuItemContext)
  return handleMenu && handleMenu(options)
}
