import ListBreadcrumbs from 'components/breadcrumbs/ListBreadcrumbs';
import StickyBannerLayout from 'components/layout/common/StickyBannerLayout';
import ListPage from 'components/view/ListPage';
import ViewLayout from 'components/view/ViewLayout';
import RenderContextProvider from 'contexts/RenderContext';
import { useConfig } from 'hooks/config';
import { useNotifier } from 'hooks/notification';
import NotFound from 'pages/NotFound';
import { GET_LIST_SCHEMA } from 'queries/list';
import React from 'react';
// @ts-ignore
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'types/graphql';
import { handleResult } from 'utils/utils';

import { useQuery } from '@apollo/client';

const List = (): JSX.Element  => {
  const {t}             = useTranslation()
  const { project }     = useConfig()
  const { id: listKey } = useParams();

  return (
    <React.Fragment>
      <Helmet>
        <title>{t('list')} | {project}</title>
      </Helmet>
      <RenderContextProvider context="list" props={{listKey}}>
        <ListResult/>
      </RenderContextProvider>
    </React.Fragment>
  );
}

const ListResult = (): JSX.Element => {
  const { id: listKey } = useParams();
  const listResult      = useQuery(GET_LIST_SCHEMA, { variables: { key: listKey } })
  const notifier        = useNotifier()
  
  return handleResult(listResult , notifier, 
    (data: any) => {
      const schema             = data.list.schema
      const translationBaseKey = `views.lists.${schema.key}`
      const augSchema          = { ...schema, kind: "list", translationBaseKey }

      return <ListResultPage schema={augSchema} links={data.list.links} />
    },
    (error: any) => {
      console.error("GraphQL error: %o", error);
      return <NotFound/>
    }
  )
}

const ListResultPage = ({ schema, links }: {schema: any, links: Link[]}) => {
  const section = { ...schema, links }
  return (
    <RenderContextProvider props={{ detailKey: schema.detail }}>
      <ViewLayout links={links} >
        <StickyBannerLayout
          banner={<ListBreadcrumbs section={section} />}
          bodySx={{ padding: "20px", overflow: "auto" }}
        >
          <ListPage schema={schema} section={section} />
        </StickyBannerLayout>
      </ViewLayout>
    </RenderContextProvider>
  )
}

export default List;
