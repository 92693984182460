import { Box, CardHeader } from '@mui/material';
import SaveButton from 'components/form/task/standard/SaveButton';
import TaskTitle from 'components/form/task/standard/TaskTitle';
import { useFormInfo } from 'hooks/form';

const StepDescription = () => {
  const formInfo = useFormInfo()
  const description = formInfo.description //getShortTaskDescription(formInfo)
  if (description)
    return (
      <Box sx={{mt: 4}}>
        {description}
      </Box>
    )
  else
    return null
}

const Save = () => {
  const formInfo = useFormInfo()
  return formInfo.isStartForm ? null : (
    <Box id='save-container' sx={{position: "relative", right: 0, width: "100%"}}>
      <Box sx={{position: 'absolute', right: 0, marginTop: "-20px", marginRight: "-20px"}}>
        <SaveButton/>
      </Box>
    </Box>
  )
}

const StepCardHeader = () => {
  const formInfo = useFormInfo()
  return (
    <>
      <Save/>
      <CardHeader
        sx={{padding: 0}}
        title={<TaskTitle/>}
        subheader={<StepDescription/>}
        style={{textAlign: 'center'}}
      />
      {/* formInfo.subject && formInfo.subject != "(none)" ? <CardHeader subheader={<TaskSubject />}/> : null */}
    </>
  )
}

export default StepCardHeader
