import { T } from 'helpers/translator';
import {
    PathEntry, PathObject, PathOrder, ProcessGroupBy, ProcessPathObject, TranslatedProcessDefinition
} from 'hooks/process';
import _ from 'lodash';
import { ProcessDefinition } from 'types/graphql';
import { toRegex } from 'utils/option-utils';

import { keyToCategory } from './keyToCategory';

export function getGroupDepth(groupBy: ProcessGroupBy) {
  switch (groupBy) {
    case 'category':    return 1
    case 'subcategory': return 2
    default:            return 0
  }
}

export function filterDefinitions(processDefinitions: TranslatedProcessDefinition[], filter: string) {
  if (!filter || !processDefinitions) {
    return processDefinitions
  } else {
    const regex  = toRegex(filter)
    const check  = (value: any) => regex.test(value)
    const lambda = (process: any) => Boolean(check(process.title) || check(process.description))

    return processDefinitions.filter(lambda);
  }
}

export function translateDefinitions(translator: T, language: string, processDefinitions: ProcessDefinition[]): TranslatedProcessDefinition[] {
  return processDefinitions.map(process => {
    const title       = translator.toProcessTitle(process.key, process.name, language)!
    const description = translator.toProcessDescription(process.key, process.description)

    return {
      ...process,
      title,
      description
    }
  })
}

export const groupDefinitions = (translator: T, defs: any[], depth: number) =>
  _.toPairs(
    _.groupBy(
      _.sortBy(defs, def => def.key),
      def => keyToCategory(translator, def.key, depth)
    )
  )
export function getPathDepth(path: string) {
  return path == "" ? 0 : path.split(".").length;
}

export function sortByPathOrder<T>(list: T[], keyFunction: ((element: T) => string), order: PathOrder): T[] {
  return list.sort((e1, e2) => order[keyFunction(e1)] - order[keyFunction(e2)]);
}
export function toPathsObject(processes: TranslatedProcessDefinition[]): { pathObject: ProcessPathObject; pathOrder: PathOrder; } {
  const processEntries = processes.map(process => [process.key, process] as PathEntry<TranslatedProcessDefinition>);
  return entriesToNestedObject(processEntries);
}

function entriesToNestedObject<T>(entries: PathEntry<T>[]): { pathObject: PathObject<T>; pathOrder: PathOrder; } {
  var counter = 0;

  var pathOrder: PathOrder = {};
  const pathObject: PathObject<T> = entries.reduce((outerObj: PathObject<T>, [key, val]: PathEntry<T>): PathObject<T> => {
    // ==== document order of key occurence ====
    const keyList = key.split('.').reduce((list, key) => {
      if (list.length == 0)
        return [key];

      else
        return [...list, `${list[list.length - 1]}.${key}`];
    }, [] as string[]);
    keyList.forEach(key => { if (pathOrder[key] == undefined) pathOrder[key] = counter++; });


    // ==== update the path object ====
    if (!key.includes('.')) {
      outerObj[key] = val;
      pathOrder[key] = counter++;

      return outerObj;
    }

    // recusively create an (nested) object to store the value
    const keys = key.split('.');
    const lastKey = keys.pop()!;
    const lastObj: PathObject<T> = keys.reduce((a: PathObject<T>, key: string): PathObject<T> => {
      if (!a[key]) {
        a[key] = {};
      }
      return a[key] as PathObject<T>;
    }, outerObj);

    // store the value in the deapest object
    lastObj[lastKey] = val;
    return outerObj;
  }, {});

  return { pathObject, pathOrder };
}

export function toProcessRef(processKey: string) {
  return `process:${processKey}`
}