import { createContext, useContext, useReducer } from 'react'
import { useNavigate }                           from 'react-router-dom'
import { useQuery, useMutation }                 from '@apollo/client'
import { setContext }                            from '@apollo/client/link/context'
import Report                                    from 'helpers/report'
import { useNotifier }                           from 'hooks/notification'
import { useTranslation }                        from 'react-i18next'
import { LOGIN_MUTATION, CURRENT_USER_QUERY }    from 'queries/login'

const AUTH_TOKEN = 'AUTH_TOKEN'

export const AuthContext = createContext({})

const initialState = {
  token: localStorage.getItem(AUTH_TOKEN) || null,
  user:  null
}

const authReducer = (state, action) => {
  switch (action.type) {
    case 'CLEAR':
      console.log("Logging out...")
      localStorage.removeItem(AUTH_TOKEN)
      return { token: null, user: null }
    case 'SET_TOKEN':
      const { token } = action.payload
      localStorage.setItem(AUTH_TOKEN, token)
      return { token: token, user: null }
    case 'SET_USER':
      const { user } = action.payload
      return { ...state, user }
    default:
      throw new Error(`Invalid action type ${action.type}`)
  }
}

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState)
  const userResult = useQuery(CURRENT_USER_QUERY, { token: state.token })

  if (userResult?.error) {
    console.error("Error loading current user: %o", userResult?.error)
  }
  else if (state.token && userResult?.data?.currentUser != state.user) {
    dispatch({ type: 'SET_USER', payload: { user: userResult.data.currentUser } })
  }

  return (
    <AuthContext.Provider value={{ state, dispatch, loading: userResult.loading }}>
      {children}
    </AuthContext.Provider>
  )
}

export const tokenPromise = () => {
  const token = localStorage.getItem("AUTH_TOKEN")
  return token ? Promise.resolve(token) : Promise.reject({ message: "no token" })
}

export const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('AUTH_TOKEN')
  const authorization = token ? `Bearer ${token}` : ''
  return { headers: { ...headers, authorization } }
})

export const useCurrentUser = () => {
  const authContext = useContext(AuthContext)
  const user = augmentUser(authContext?.state?.user)

  return { user, loading: authContext.loading }
}

const augmentUser = (user) => {
  if (user) {
    const name = user.givenName + ' ' + user.familyName
    return { ...user, name }
  } else
    return null
}

export const useAuthNavigation = () => {
  const authContext = useContext(AuthContext)
  const navigate    = useNavigate()

  return {
    setToken: (token) => authContext.dispatch({ type: 'SET_TOKEN', payload: { token }}),
    login:         () => navigate('/login'),
    onClickLogin:  () => navigate('/login'),
    onClickLogout: () => { authContext.dispatch({ type: 'CLEAR' }); navigate('/logout'); },
  }
}

