import { lighten }    from '@mui/material';

export const createListItemSx = (props) => ({
  menuitem: {
  '& .MuiListItemButton-root': {
    color: theme => theme.palette.text.secondary, 
    "&:hover": {
      backgroundColor: theme => lighten(theme.palette.primary.main,0.92)
    },
    '& .MuiListItemIcon-root': {
      minWidth: 0,
      width: 'fit-content',
      maxWidth: '50px',
      overflow: 'visible',
      marginRight: '16px',
      color: theme => props.active ? theme.palette.primary.main : theme.palette.text.secondary,
    },
    '& .MuiListItemText-root': {
        '& .MuiListItemText-primary': {
          fontWeight: theme => props.active ? theme.typography.fontWeightBold : theme.typography.fontWeightMedium,
          color: theme => props.active ? theme.palette.primary.main : 'unset',
          fontSize: 14,
          letterSpacing: 0,
          textTransform: 'none',
        },
      }
    } 
  }
})

export const createMenuItemSx = (props) => ({
  menuitem: {
  '& .MuiMenuItem-root': {
    color: theme => theme.palette.text.primary, 
    "&:hover": {
      backgroundColor: theme => lighten(theme.palette.primary.main,0.92)
    },
    '& .MuiListItemIcon-root': {
      color: theme => theme.palette.text.secondary,
    },
    '& .MuiListItemText-root': {
        '& .MuiListItemText-primary': {
          fontWeight: theme => theme.typography.fontWeightNormal,
          fontSize: 14,
          letterSpacing: 0,
          textTransform: 'none',
        },
      }
    } 
  }
})

