import * as React         from 'react'
import { useTranslation } from 'react-i18next'
import {
  TableCell,
  TableRow as MuiTableRow
} from '@mui/material'
import { RenderValue }    from 'components/render'
import TableRowMenu       from 'components/table/TableRowMenu'
import { useMenuItems }   from 'hooks/menu'
import { useNotifier }    from 'hooks/notification'

export default function TableRow({row, columns, index}) {
  const [contextMenu, setContextMenu] = React.useState(null);
  const menu                          = useMenuItems(row)
  const notifier                      = useNotifier()
  const { t }                         = useTranslation()

  const handleContextMenu = (event) => {
    // disable context menu, when text is selected
    if (window.getSelection().type == 'Range')
      return false

    event.preventDefault();

    if (menu?.length)
      setContextMenu(
        contextMenu === null
          ? {
              x: event.clientX - 15,
              y: event.clientY - 6,
            }
          : null
      );
    else 
      notifier.message(t('error.contextmenu.nocontext'))
  };

  return (
      <MuiTableRow 
        sx={row.rowProps ? row.rowProps : {}}
        id={"row_" + index}
        gears-id={row.id}
        onContextMenu={handleContextMenu}
        onClick={row.onClick}          
        style={{cursor: row.onClick ? 'pointer' : undefined}}
        hover
        key={index}
      >
        <TableRows columns={columns} row={row}/>
        <TableRowMenu menu={menu} contextMenu={contextMenu} setContextMenu={setContextMenu}/>
      </MuiTableRow>
  );
}

const TableRows = ({row, columns}) => {
  return columns.map( (column, index) =>  {
      const sx =row.rowProps?.fontWeight?  { fontWeight: row.rowProps.fontWeight } : {}

      return (
        <TableCell key={index} {...column.cellProps} >
          <RenderValue sx={sx} displayProps={column.displayProps}>
            {_.get(row, "cells." + column.field)}
          </RenderValue>
        </TableCell>
      )
    }
  )
}
