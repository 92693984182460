import { Content, ContentViewEvent } from 'helpers/content';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import { defaultStyles, FileIcon } from 'react-file-icon';
import { useTranslation } from 'react-i18next';
import { Anchor, SetState } from 'types/react';
import Types from 'types/types';
import { renderHtml } from 'utils/html';
import { openInNewTab } from 'utils/utils';

import {
    Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography, useTheme
} from '@mui/material';

import { viewable } from './utils';

const ContentViewer = ({content: inContent, open, setOpen}:{ content: Content, open: ContentViewEvent, setOpen: SetState<ContentViewEvent>}) => {
  const content           = Types.toContent(inContent)
  const [error, setError] = useState<boolean>(false)
  const { t }             = useTranslation()
	const theme             = useTheme()
  const [openl, setOpenl] = useState<boolean>(false)

  useEffect(() => {
    if (!content.hasData || (!error && !open)){
      return;
    }
  
    const ctrlOpen = typeof open != "boolean" && open?.ctrlKey
    if (error || ctrlOpen){
      openInNewTab(content.url)
      setOpen(null)
    } else if (open && viewable(content)) {
      setOpenl(true)
    } else if (open) {
      openInNewTab(content.url)
      setOpen(null)
    } 
  }, [open, error])

  const onClose = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    setOpenl(false)
    setOpen(null)
  }

  return (
	  <Dialog sx={{width:"fit-content", maxWidth: "unset"}} open={openl} onClose={onClose} >
			<DialogTitle textAlign="center" sx={{fontSize: theme.typography.h3.fontSize}}>{content?.name}</DialogTitle>
			<DialogContent  sx={{pt: 3, pb: 0}}>
        <ShowFile content={content} setError={setError} error={error}/>
      </DialogContent>
      <DialogActions sx={{padding: 0}}>
        <Button id='close-button' onClick={onClose}>{t('close')}</Button>
      </DialogActions>
		</Dialog>
	)
}

const ShowFile = ({content, error, setError}: {content: Content, error: boolean, setError: SetState<boolean>}): JSX.Element => {
  const extension   = content.extension
  const style       = defaultStyles.hasOwnProperty(extension) ? defaultStyles[extension] : {}
  const defaultView = <FileIcon extension={extension} {...style} />

  if (error || !viewable(content))
    return defaultView

  switch (true) {
    case content.type.startsWith("image/") :
      return (
        <img
          src={content.url}
          onError={() => { console.error("Unable to load preview image for file: %o", content); setError(true) }}
        />
      )

    case content.type == "application/pdf":
      return (
        <Box sx={{ height: "calc(100vh - 150px)", maxHeight: "100%", overflow: undefined }}>
          <object onError={() => setError(true)} data={`${content.url}#view=FitH`} type="application/pdf" height={"98%"} width={"650px"} >
            <Typography>Could not load contents of file in the browser. Go <Link onClick={content.download}>to your file</Link></Typography>
          </object>
        </Box>
      )

    case content.type == "text/html":
        return <React.Fragment>{renderHtml(content?.file.text())}</React.Fragment>

    //case !error && (file.extension == "doc" || file.extension == "docx"):
    //  // microsoft 365 viewer:
    //  return (
    //    <iframe
    //      onError={() => setError(true)}
    //      src={`https://view.officeapps.live.com/op/embed.aspx?src=${file.preview}`}
    //      width='100%'
    //      height='623px'
    //      frameborder='0'
    //    >
    //      This is an embedded <a target='_blank' href='http://office.com'>Microsoft Office</a> document, powered by
    //      <a target='_blank' href='http://office.com/webapps'>Office Online</a>.
    //    </iframe>
    //  )

    //  // google viewer:
    //  // return <iframe onError={() => setError(true)} src={`https://docs.google.com/gview?url=${file.preview}&embedded=true`}/>

    default:
      return defaultView
  }
}

export default ContentViewer
