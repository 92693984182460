import Loader from 'components/common/Loader';
import MuiIcon from 'components/common/MuiIcon';
import SearchField from 'components/common/SearchField';
import StickyBannerLayout from 'components/layout/common/StickyBannerLayout';
import ProcessRenderProvider from 'contexts/ProcessRenderContext';
import { ProcessInfo, useTranslatedProcesses } from 'hooks/process';
import { useProcessRenderContext, useTiles } from 'hooks/render';
import { useTranslator } from 'hooks/translator';
import React, { useState } from 'react';
import { Anchor, SetState } from 'types/react';

import { Close as CloseIcon } from '@mui/icons-material';
import { Box, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';

import { PathViewer } from './ProcessOptionBar';
import Tile from './Tile';

const ProcesseAppsItem = () => {
  const [anchorEl, setAnchorEl] = useState<Anchor>(null);

  const handleClick = (event: React.SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  }

  return (
    <React.Fragment>
      <AppsButton onClick={handleClick} />
      <AppsMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </React.Fragment>
  )
}

const AppsButton = ({onClick}: {onClick: (e: React.SyntheticEvent) => void}) => {
  const iconStyle = { height: '32px', width: '32px' }
  const theme     = useTheme()
  // @ts-ignore
  const color     = theme?.components?.banner?.color
  const { t } = useTranslator()

  return (
    <Tooltip disableInteractive title={t('processes.apps')}>
      <IconButton id="apps-button" onClick={onClick} size="small" sx={{ color }} >
        <MuiIcon name='Apps' sx={iconStyle}/>
      </IconButton>
    </Tooltip>
  )
}

export const AppsMenu = ({anchorEl, setAnchorEl}: {anchorEl: Anchor, setAnchorEl: SetState<Anchor>}) => {
  const open = Boolean(anchorEl);
  const {loading, processInfo} = useTranslatedProcesses()

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={menuProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}

      >
      <Box className="close-container" sx={{ position: "relative"}}>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            left: 275,
            top: -5,
            zIndex: 1000,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
        { loading 
          ? <AppsLoader/>
          : <AppsPanel onClick={handleClose} processInfo={processInfo!}/>
        }
      </Menu>
  )
}

const AppsLoader = () => {
  return (
    <Box sx={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'center', padding: "30px" }}>
      <Loader size='tiny' />
    </Box>
  ) 
}

const AppsPanel = ({processInfo, onClick}: {processInfo: ProcessInfo, onClick?: (e?: React.SyntheticEvent) => void}) => {
  return (
    <ProcessRenderProvider processInfo={processInfo} processProps={{groupBy: "subcategory", groupByKey: "PROCESS_GROUPBY_MENU", processRendering: "tiles", processRenderingKey: "PROCESS_RENDERING_MENU"}}>
      <Box id='process-app-container' sx={{display: "flex", maxHeight: "100%", overflow: "auto"}}>
        <StickyBannerLayout banner={<ProcessOptionBar />} >
          <ProcessAppsView onClick={onClick} />

        </StickyBannerLayout>
      </Box>
    </ProcessRenderProvider>
  )
}

const ProcessAppsView = ({onClick}: {onClick?: (e?: React.SyntheticEvent) => void}) => {
  const tiles = useTiles() 

  return (
    <Box className='tiles' sx={{ padding: "20px", height:"fit-content", display: "flex", maxWidth: "320px", flexWrap: "wrap", overflow: "auto", gap: "10px" }}>
      { tiles.map((tile, index) => 
        <Tile key={index} {...tile} tileProps={{maxLines: "1", tileWidth: "80px", iconSize: "50px", padding: "5px", fontSize: "11px", textMargin: "5px"}} onClick={onClick}/>) 
      }
    </Box>
  )
}

const ProcessOptionBar = () => {
  const {filter, setFilter, setCurrentFilter, groupBy, setGroupBy, processRendering, setProcessRendering, path, setPath} = useProcessRenderContext()
  const { t } = useTranslator()

  return (
    <Box sx={{ display: "flex", width: "100%", padding: "15px" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ width: "90%" }}>
          <SearchField
            placeholder={t('filter.search', { variables: { item: t('filter.process') } })!}
            value={filter}
            setValue={setFilter}
            setCurrentValue={setCurrentFilter}
            autoFocus={true}
            id='menu-search'
          />
        </Box>
        { processRendering != 'standard' 
          ? ( <Box sx={{marginTop: "20px"}}>
               <PathViewer path={path} setPath={setPath}/>
             </Box> )
            : null
        }
      </Box>
    </Box>
  )
}


const menuProps = {
  elevation: 0,
  sx: {
    display: "flex",
    minWidth: "320px",
    width: "320px",
    maxHeight: "90%",
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: -20,
      right: 18,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
}

export default ProcesseAppsItem