import { useEffect, useState }       from 'react';
import { useTranslation }            from 'react-i18next';
import { useFormikContext }          from 'formik';
import {
  Save as SaveIcon,
  SaveOutlined as SaveOutlinedIcon } from '@mui/icons-material'
import { Tooltip, IconButton, Box }  from '@mui/material';
import { useMutation }               from '@apollo/client';
import Loader                        from 'components/common/Loader';
import { useNotifier }               from 'hooks/notification';
import { useTaskId }                 from 'hooks/form';
import { SAVE_TASK }                 from 'queries/task';
import Report from 'helpers/report';
import { extractFiles } from 'utils/utils';
import { useTranslator } from 'hooks/translator';

const SaveButton = () => {
  const { t }                   = useTranslation()
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading]   = useState(false)
  const { translator }          = useTranslator()
  const [doSaveTask]            = useMutation(SAVE_TASK)
  const id                      = useTaskId()
  const formik                  = useFormikContext()
  const notifier                = useNotifier()

  const handleSave = (e) => {
    const values = formik?.values
    const [rValues, files]  = extractFiles(values)

    console.log("handleSave: id=%o, values=%o", id, values)
    if (files.length > 0)
      notifier.warning("Files are not yet supported with the save operation") 

    console.log("handleSave: id=%o, values=%o files=%o", id, rValues, files)
    setDisabled(true)
    setLoading(true)
    doSaveTask({ variables: { id, values: rValues, files }})
      .then(
        result => {
          setLoading(false)
        },
        error  => {
          setLoading(false)
          setDisabled(false)
          const report = Report.from(error, translator, { category: Report.backend})
          report.addToNotifier(notifier)
          console.error("Save error: %s", report.verboseMessage)
        }
      )
  }

  useEffect(() => {
    if (!disabled)
      return

    const timeout = 1500
    const timer = setTimeout(() => {
      setDisabled(false);
    }, timeout);

    return () => {clearTimeout(timer)}
  }, [disabled]);

  const ButtonLoader = () => (
    <Box sx={{paddingTop: '4px', paddingLeft: '5px'}}>
      <Loader size='small'/> 
    </Box>
  )
  
  const unabled = disabled || loading
  const title = unabled ? "" : t("save")
  return unabled 
    ? <ButtonLoader/> 
    : <Tooltip disableInteractive title={title}>
        <span>
          <IconButton tabIndex={-1} id='save-button' size="normal" disabled={unabled} onClick={handleSave} >
            { unabled 
              ? <SaveOutlinedIcon fontSize="normal" /> 
              : <SaveIcon fontSize="normal" /> }
          </IconButton>
        </span>
      </Tooltip>
}

export default SaveButton