import { getPathDepth } from 'components/process/utils/process';
import { useConfig } from 'hooks/config';
import { ProcessGroupBy, ProcessInfo, ProcessRendering } from 'hooks/process';
import { useLocalState } from 'hooks/state';
import React, { createContext, useEffect, useState } from 'react';
import { SetState } from 'types/react';
import { useDebouncedState } from 'utils/utils';

export type ProcessRenderProps = {
  path: string
  setPath: SetState<string>
  filter: string
  setFilter: SetState<string>
  setCurrentFilter: SetState<string>
  groupBy: ProcessGroupBy
  setGroupBy: SetState<ProcessGroupBy>
  processRendering: ProcessRendering
  setProcessRendering: SetState<ProcessRendering>
  processInfo: ProcessInfo
}

type ProcessStorageProps = {
  groupBy: ProcessGroupBy
  groupByKey: string
  processRendering: ProcessRendering
  processRenderingKey: string
}

const defaultProcessStorageProps: ProcessStorageProps = {
  groupBy: 'category',
  groupByKey: 'PROCESS_GROUPBY',
  processRendering: "tiles",
  processRenderingKey: "PROCESS_RENDERING"
}

export const ProcessRenderContext = createContext<ProcessRenderProps>({} as ProcessRenderProps);

const ProcessRenderProvider = ({children, processInfo, processProps}: {children: React.ReactNode, processInfo: ProcessInfo, processProps?: Partial<ProcessStorageProps> }) => {
  const storageProps: ProcessStorageProps     = {...defaultProcessStorageProps, ...processProps}
  const [filter, setFilter, setCurrentFilter] = useDebouncedState('', 500)
  const {props: {addIcons: addProcessIcons}}            = useConfig()
  const [groupBy, setGroupBy]                 = useLocalState<ProcessGroupBy>(storageProps.groupByKey, storageProps.groupBy)
  const [path, setPath]                       = useState<string>("")

  const [processRendering, setProcessRendering]: [ProcessRendering, SetState<ProcessRendering>] = addProcessIcons
    ? useLocalState<ProcessRendering>(storageProps.processRenderingKey, storageProps.processRendering) 
    : ['standard', () => {}]

  useEffect(() => {
    const currentDepth = getPathDepth(path)

    if (groupBy == 'none' && currentDepth > 0)
      setPath("")
    else if (groupBy == 'category' && currentDepth > 1)
      setPath(path.split(".").slice(0, 1).join("."))

  }, [groupBy])

  useEffect(() => {
    if (addProcessIcons) {
      if (processRendering == 'standard')
        setProcessRendering('tiles')
    } else {
      if (processRendering != 'standard')
        setProcessRendering('standard')
    }
  }, [addProcessIcons])

  return (
    <ProcessRenderContext.Provider value={{filter, setFilter, setCurrentFilter, groupBy, setGroupBy, processRendering, setProcessRendering, path, setPath: (path) => {setCurrentFilter(""), setPath(path)}, processInfo}}>
      {children}
    </ProcessRenderContext.Provider>
  )
}

export default ProcessRenderProvider;
