import { BrowserRouter, HashRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import {ApolloClient, InMemoryCache, ApolloProvider, from, DefaultOptions, ApolloLink} from '@apollo/client'
import { InMemoryCacheConfig } from '@apollo/client/cache/inmemory/types'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

import App from './App'
import { authLink, AuthProvider } from './authentication/main'
import { env } from './utils/utils'
import * as serviceWorker from './utils/serviceWorker'
import i18n from './utils/i18n'
import { ENABLE_HTML } from 'components/render/RenderValue'
import { SERVER } from 'helpers/runtime'

const NAME        = env('NAME')
const VERSION     = env('VERSION')
const TITLE       = env('TITLE')
const THEME       = env('THEME')
const AUTH_MODE   = env('AUTH_MODE')
const ROUTER_MODE = env('ROUTER_MODE')

console.log(`${NAME} ${VERSION} with TITLE=${TITLE}, ENABLE_HTML=${ENABLE_HTML}, THEME=${THEME}, SERVER=${SERVER}, AUTH_MODE=${AUTH_MODE}, ROUTER_MODE=${ROUTER_MODE}`)
console.debug('i18n', i18n)
window.document.title = TITLE

// see https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
const mergePolicy: InMemoryCacheConfig = {}
const disableCache = true
const disableCacheOpts: DefaultOptions = {
  watchQuery: { fetchPolicy: 'network-only' },
  query: { fetchPolicy: 'network-only' },
}

const getRouter = (mode: string) => {
  switch (mode.toLowerCase()) {
    case 'browser': return BrowserRouter
    case 'hash':    return HashRouter
    default:        throw new Error('Invalid router mode: ' + mode)
  }
}

const uploadLink: ApolloLink = createUploadLink({ uri: SERVER + 'graphql'})
const fromLink: ApolloLink   = from([authLink, uploadLink])
const client     = new ApolloClient({
  link: fromLink,
  cache: new InMemoryCache(mergePolicy),
  defaultOptions: disableCache ? disableCacheOpts : {},
})
const Router = getRouter(ROUTER_MODE)

// @ts-ignore
const root = createRoot(document.getElementById('root'))
root.render(
  <ApolloProvider client={client}>
    <Router basename={env('ROUTER_BASE')}>
      {/*<Suspense fallback="Loading...">*/}
        <AuthProvider>
          <App/>
        </AuthProvider>
      {/*</Suspense>*/}
    </Router>
  </ApolloProvider>
)

serviceWorker.unregister()
